import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContextProvider from "./Context/ContextProvider";
import AboutTwo from "./Pages/About/AboutTwo";
import ContactTwo from "./Pages/Contact/ContactTwo";
import Clausas from "./Pages/Home/Clausas";
import PortfiloTwo from "./Pages/Portfilo/PortfiloTwo";
import ResumeTwo from "./Pages/Resume/ResumeTwo";
import FoxysWorld from "./Pages/Resume/FoxysWorld";
import PrivacyPolicy from "./Pages/FoxysWorld/PrivacyPolicy";
import TermsandConditions from "./Pages/FoxysWorld/TermsandConditions";
import NotFound from "./Share/NotFound";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
    AOS.refresh();
  }, []);
  return (
    <>
      <ContextProvider>
        <BrowserRouter>
          <Routes>
 
            <Route path="/" element={<Clausas />}>
              <Route index element={<AboutTwo />} />
              <Route path="about" element={<AboutTwo />} />
              <Route path="resume" element={<ResumeTwo />} />
              <Route path="contact" element={<ContactTwo />} />
              <Route path="nft" element={<PortfiloTwo />} />
              <Route path="foxysworld" element={<FoxysWorld />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms" element={<TermsandConditions />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ContextProvider>
    </>
  );
}

export default App;
